<template>
  <div class="progress" :style="style">
    <div class="divider">
      <span v-for="span in lineQty" :key="span"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    animationSpeed: {type: String, default: '2.1s'},
    borderRadius: {type: String, default: '6px'},
    color: {type: String, default: 'blue'},
    height: {type: String, default: '10px'},
    lineQty: {type: Number, default: 10},
    lineWidth: {type: String, default: '6%'},
    width: {type: String, default: '100%'},
  },
  data() {
    return {
      colors: {
        blue: {
          bgColor: '#839ff6',
          stripeColor: '#4e77f3',
        },
        green: {
          bgColor: '#70c46c',
          stripeColor: '#33ac2e',
        },
        orange: {
          bgColor: '#ff8552',
          stripeColor: '#ff5108',
        },
        red: {
          bgColor: '#e2727f',
          stripeColor: '#d63649',
        },
        yellow: {
          bgColor: '#f9d373',
          stripeColor: '#f7c137',
        }
      }
    }
  },
  computed: {
    style() {
      return {
        '--animation_speed': this.animationSpeed,
        '--border_radius': this.borderRadius,
        '--bgColor': this.colors[this.color].bgColor,
        '--height': this.height,
        '--line_width': this.lineWidth,
        '--stripeColor': this.colors[this.color].stripeColor,
        '--width': this.width,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.progress {
  border-radius: var(--border_radius);
  height: var(--height);
  overflow: hidden;
  position: relative;
  width: var(--width);

  .divider {
    animation: divider linear infinite var(--animation_speed);
    background: var(--bgColor);
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: absolute;
    width: 140%;

    span {
      background: var(--stripeColor);
      height: 100%;
      transform: skew(-20deg);
      width: var(--line_width);
    }
  }
}

@keyframes divider {
  0% {
    left: -30%;
  }
  25% {
    left: 0;
  }
  50% {
    left: -10%;
  }
  75% {
    left: -20%;
  }
  100% {
    left: -30%;
  }
}
</style>